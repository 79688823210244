<template>
  <div>
    <div class="screen-box">

      <div class="screenTime">
        <label>时间</label>
        <a-month-picker placeholder="筛选时间"  @change="onChange" />
      </div>
    </div>
    <a-table
      :columns="columns"
      rowKey="plogid"
      :data-source="data"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
    >
      <template slot="pointscount" slot-scope="pointscount,items">
        <!-- {{item.changetype}} -->
        <span class="key">{{items.changetype==0?'-':'+'}}{{items.pointscount}}</span>/积分
      </template>
    </a-table>
  </div>
</template>
<script>
import { getXeupointslog } from "@/api/manage";

// const columns = [
//   {
//     title: "订单号",
//     dataIndex: "odno",
//     width: "10%",
//     scopedSlots: { customRender: "odno" },
//   },
//   {
//     title: "类型",
//     dataIndex: "ordertype",
//     width: "20%",
//     scopedSlots: { customRender: "ordertype" },
//   },
//   {
//     title: "实际消费金额",
//     dataIndex: "actualamount",
//     width: "20%",
//   },
//   {
//     title: "备注",
//     dataIndex: "UPDATED_TIME",
//     width: "20%",
//     customRender: (text, row) => {
//       return (<span>{row.payamount} {row.actualamount}</span>)
//     },
//   },
//   {
//     title: "消费时间",
//     dataIndex: "UPDATED_TIME",
//   },
// ];

export default {
  data() {
    return {
      data: [],
      pagination: {},
      loading: false,
      time:'',
      columns: [
  {
    title: "类型",
    dataIndex: "prulename",
    width: "30%",
    scopedSlots: { customRender: "prulename" },
  },
  {
    title: "积分",
    dataIndex: "pointscount",
    width: "30%",
    scopedSlots: { customRender: "pointscount" },
  },

  {
    title: "时间",
    width: "40%",
    dataIndex: "CREATED_TIME",
  },
],
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination);
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.fetch({
        results: pagination.pageSize,
        pageNo: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },
    // handleChange(value) {
    //   console.log(`selected ${value}`);
    // },
    onChange(date, dateString) {
      console.log(date, dateString);
      this.time=dateString
      this.fetch()
    },
    fetch(params = {}) {
      this.loading = true;
      getXeupointslog({
        pageSize: 10,
        time: this.time,
        // results: 10,
        ...params,
      }).then(({ data }) => {
        const pagination = { ...this.pagination };
        // Read total count from server
        // pagination.total = data.totalCount;
        pagination.total = data.total;
        this.loading = false;
        this.data = data.data;
        this.pagination = pagination;
      });
    },
    
  },
};
</script>

<style lang="less" scoped>
.screen-box{
  display: flex;
  margin-bottom: 18px;
  .screenType,.screenTime{
    margin-right: 60px;
    label{
      margin-right: 12px;
    }
  }
}
.key{
  font-size: 20px;
  color: #b37474;
  margin-right: 6px;
}
</style>